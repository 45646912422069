import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './transaction.reducer';

export const TransactionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const transactionEntity = useAppSelector(state => state.transaction.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="transactionDetailsHeading">
          <Translate contentKey="cryptoweApp.transaction.detail.title">Transaction</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.id}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="cryptoweApp.transaction.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.amount}</dd>
          <dt>
            <span id="date">
              <Translate contentKey="cryptoweApp.transaction.date">Date</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.date ? <TextFormat value={transactionEntity.date} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="cryptoweApp.transaction.type">Type</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.type}</dd>
          <dt>
            <span id="externalId">
              <Translate contentKey="cryptoweApp.transaction.externalId">External Id</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.externalId}</dd>
          <dt>
            <span id="merchantName">
              <Translate contentKey="cryptoweApp.transaction.merchantName">Merchant Name</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.merchantName}</dd>
          <dt>
            <span id="fromWalletAddress">
              <Translate contentKey="cryptoweApp.transaction.fromWalletAddress">From Wallet Address</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.fromWalletAddress}</dd>
          <dt>
            <span id="receiptUrl">
              <Translate contentKey="cryptoweApp.transaction.receiptUrl">Receipt Url</Translate>
            </span>
          </dt>
          <dd>{transactionEntity.receiptUrl}</dd>
          <dt>
            <Translate contentKey="cryptoweApp.transaction.token">Token</Translate>
          </dt>
          <dd>{transactionEntity.token ? transactionEntity.token.id : ''}</dd>
          <dt>
            <Translate contentKey="cryptoweApp.transaction.wallet">Wallet</Translate>
          </dt>
          <dd>{transactionEntity.wallet ? transactionEntity.wallet.id : ''}</dd>
          <dt>
            <Translate contentKey="cryptoweApp.transaction.card">Card</Translate>
          </dt>
          <dd>{transactionEntity.card ? transactionEntity.card.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/transaction" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/transaction/${transactionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TransactionDetail;
