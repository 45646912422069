import dayjs from 'dayjs';
import { NotificationType } from 'app/shared/model/enumerations/notification-type.model';
import { NotificationStatus } from 'app/shared/model/enumerations/notification-status.model';

export interface INotification {
  id?: number;
  date?: dayjs.Dayjs | null;
  content?: string | null;
  error?: string | null;
  type?: keyof typeof NotificationType | null;
  status?: keyof typeof NotificationStatus | null;
}

export const defaultValue: Readonly<INotification> = {};
