import { ICard } from 'app/shared/model/card.model';
import { IBlockchain } from 'app/shared/model/blockchain.model';

export interface IWallet {
  id?: number;
  availableBalance?: number | null;
  address?: string | null;
  card?: ICard | null;
  blockchain?: IBlockchain | null;
}

export const defaultValue: Readonly<IWallet> = {};
