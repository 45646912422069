import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './wallet.reducer';

export const WalletDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const walletEntity = useAppSelector(state => state.wallet.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="walletDetailsHeading">
          <Translate contentKey="cryptoweApp.wallet.detail.title">Wallet</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{walletEntity.id}</dd>
          <dt>
            <span id="availableBalance">
              <Translate contentKey="cryptoweApp.wallet.availableBalance">Available Balance</Translate>
            </span>
          </dt>
          <dd>{walletEntity.availableBalance}</dd>
          <dt>
            <span id="address">
              <Translate contentKey="cryptoweApp.wallet.address">Address</Translate>
            </span>
          </dt>
          <dd>{walletEntity.address}</dd>
          <dt>
            <Translate contentKey="cryptoweApp.wallet.card">Card</Translate>
          </dt>
          <dd>{walletEntity.card ? walletEntity.card.id : ''}</dd>
          <dt>
            <Translate contentKey="cryptoweApp.wallet.blockchain">Blockchain</Translate>
          </dt>
          <dd>{walletEntity.blockchain ? walletEntity.blockchain.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/wallet" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/wallet/${walletEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default WalletDetail;
