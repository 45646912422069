import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getFaqCategories } from 'app/entities/faq-category/faq-category.reducer';
import { createEntity, getEntity, reset, updateEntity } from './faq.reducer';

export const FaqUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const faqCategories = useAppSelector(state => state.faqCategory.entities);
  const faqEntity = useAppSelector(state => state.faq.entity);
  const loading = useAppSelector(state => state.faq.loading);
  const updating = useAppSelector(state => state.faq.updating);
  const updateSuccess = useAppSelector(state => state.faq.updateSuccess);

  const handleClose = () => {
    navigate(`/faq${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getFaqCategories({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.order !== undefined && typeof values.order !== 'number') {
      values.order = Number(values.order);
    }

    const entity = {
      ...faqEntity,
      ...values,
      faqCategory: faqCategories.find(it => it.id.toString() === values.faqCategory?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...faqEntity,
          faqCategory: faqEntity?.faqCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cryptoweApp.faq.home.createOrEditLabel" data-cy="FaqCreateUpdateHeading">
            <Translate contentKey="cryptoweApp.faq.home.createOrEditLabel">Create or edit a Faq</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="faq-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cryptoweApp.faq.question')}
                id="faq-question"
                name="question"
                data-cy="question"
                type="text"
              />
              <ValidatedField label={translate('cryptoweApp.faq.answer')} id="faq-answer" name="answer" data-cy="answer" type="textarea" />
              <ValidatedField
                label={translate('cryptoweApp.faq.disabled')}
                id="faq-disabled"
                name="disabled"
                data-cy="disabled"
                check
                type="checkbox"
              />
              <ValidatedField label={translate('cryptoweApp.faq.order')} id="faq-order" name="order" data-cy="order" type="text" />
              <ValidatedField
                id="faq-faqCategory"
                name="faqCategory"
                data-cy="faqCategory"
                label={translate('cryptoweApp.faq.faqCategory')}
                type="select"
              >
                <option value="" key="0" />
                {faqCategories
                  ? faqCategories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/faq" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FaqUpdate;
