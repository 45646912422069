import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Blockchain from './blockchain';
import BlockchainDetail from './blockchain-detail';
import BlockchainUpdate from './blockchain-update';
import BlockchainDeleteDialog from './blockchain-delete-dialog';

const BlockchainRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Blockchain />} />
    <Route path="new" element={<BlockchainUpdate />} />
    <Route path=":id">
      <Route index element={<BlockchainDetail />} />
      <Route path="edit" element={<BlockchainUpdate />} />
      <Route path="delete" element={<BlockchainDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BlockchainRoutes;
