import dayjs from 'dayjs';
import { IToken } from 'app/shared/model/token.model';
import { IWallet } from 'app/shared/model/wallet.model';
import { ICard } from 'app/shared/model/card.model';
import { TransactionType } from 'app/shared/model/enumerations/transaction-type.model';

export interface ITransaction {
  id?: number;
  amount?: number | null;
  date?: dayjs.Dayjs | null;
  type?: keyof typeof TransactionType | null;
  externalId?: string | null;
  merchantName?: string | null;
  fromWalletAddress?: string | null;
  receiptUrl?: string | null;
  token?: IToken | null;
  wallet?: IWallet | null;
  card?: ICard | null;
}

export const defaultValue: Readonly<ITransaction> = {};
