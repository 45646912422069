import { IBlockchain } from 'app/shared/model/blockchain.model';

export interface IToken {
  id?: number;
  name?: string | null;
  symbol?: string | null;
  disabled?: boolean | null;
  blockchains?: IBlockchain[] | null;
}

export const defaultValue: Readonly<IToken> = {
  disabled: false,
};
