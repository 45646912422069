import { IToken } from 'app/shared/model/token.model';
import { IBlockchain } from 'app/shared/model/blockchain.model';

export interface ITokenBlockchain {
  id?: number;
  disabled?: boolean | null;
  contractAddress?: string | null;
  token?: IToken | null;
  blockchain?: IBlockchain | null;
}

export const defaultValue: Readonly<ITokenBlockchain> = {
  disabled: false,
};
