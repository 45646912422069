export enum CardStatus {
  ACTIVE = 'ACTIVE',

  DELETED = 'DELETED',

  EXPIRED = 'EXPIRED',

  FROZEN = 'FROZEN',

  INACTIVE = 'INACTIVE',

  BLOCKED = 'BLOCKED',
}
