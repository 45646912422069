import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Faq from './faq';
import FaqDetail from './faq-detail';
import FaqUpdate from './faq-update';
import FaqDeleteDialog from './faq-delete-dialog';

const FaqRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Faq />} />
    <Route path="new" element={<FaqUpdate />} />
    <Route path=":id">
      <Route index element={<FaqDetail />} />
      <Route path="edit" element={<FaqUpdate />} />
      <Route path="delete" element={<FaqDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FaqRoutes;
