import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getTokens } from 'app/entities/token/token.reducer';
import { getEntities as getWallets } from 'app/entities/wallet/wallet.reducer';
import { getEntities as getCards } from 'app/entities/card/card.reducer';
import { TransactionType } from 'app/shared/model/enumerations/transaction-type.model';
import { createEntity, getEntity, reset, updateEntity } from './transaction.reducer';

export const TransactionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tokens = useAppSelector(state => state.token.entities);
  const wallets = useAppSelector(state => state.wallet.entities);
  const cards = useAppSelector(state => state.card.entities);
  const transactionEntity = useAppSelector(state => state.transaction.entity);
  const loading = useAppSelector(state => state.transaction.loading);
  const updating = useAppSelector(state => state.transaction.updating);
  const updateSuccess = useAppSelector(state => state.transaction.updateSuccess);
  const transactionTypeValues = Object.keys(TransactionType);

  const handleClose = () => {
    navigate(`/transaction${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTokens({}));
    dispatch(getWallets({}));
    dispatch(getCards({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.amount !== undefined && typeof values.amount !== 'number') {
      values.amount = Number(values.amount);
    }
    values.date = convertDateTimeToServer(values.date);

    const entity = {
      ...transactionEntity,
      ...values,
      token: tokens.find(it => it.id.toString() === values.token?.toString()),
      wallet: wallets.find(it => it.id.toString() === values.wallet?.toString()),
      card: cards.find(it => it.id.toString() === values.card?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          date: displayDefaultDateTime(),
        }
      : {
          type: 'CREDIT',
          ...transactionEntity,
          date: convertDateTimeFromServer(transactionEntity.date),
          token: transactionEntity?.token?.id,
          wallet: transactionEntity?.wallet?.id,
          card: transactionEntity?.card?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cryptoweApp.transaction.home.createOrEditLabel" data-cy="TransactionCreateUpdateHeading">
            <Translate contentKey="cryptoweApp.transaction.home.createOrEditLabel">Create or edit a Transaction</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="transaction-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cryptoweApp.transaction.amount')}
                id="transaction-amount"
                name="amount"
                data-cy="amount"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.transaction.date')}
                id="transaction-date"
                name="date"
                data-cy="date"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('cryptoweApp.transaction.type')}
                id="transaction-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {transactionTypeValues.map(transactionType => (
                  <option value={transactionType} key={transactionType}>
                    {translate(`cryptoweApp.TransactionType.${transactionType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cryptoweApp.transaction.externalId')}
                id="transaction-externalId"
                name="externalId"
                data-cy="externalId"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.transaction.merchantName')}
                id="transaction-merchantName"
                name="merchantName"
                data-cy="merchantName"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.transaction.fromWalletAddress')}
                id="transaction-fromWalletAddress"
                name="fromWalletAddress"
                data-cy="fromWalletAddress"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.transaction.receiptUrl')}
                id="transaction-receiptUrl"
                name="receiptUrl"
                data-cy="receiptUrl"
                type="text"
              />
              <ValidatedField
                id="transaction-token"
                name="token"
                data-cy="token"
                label={translate('cryptoweApp.transaction.token')}
                type="select"
              >
                <option value="" key="0" />
                {tokens
                  ? tokens.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="transaction-wallet"
                name="wallet"
                data-cy="wallet"
                label={translate('cryptoweApp.transaction.wallet')}
                type="select"
              >
                <option value="" key="0" />
                {wallets
                  ? wallets.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="transaction-card"
                name="card"
                data-cy="card"
                label={translate('cryptoweApp.transaction.card')}
                type="select"
              >
                <option value="" key="0" />
                {cards
                  ? cards.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/transaction" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TransactionUpdate;
