import { IToken } from 'app/shared/model/token.model';

export interface IBlockchain {
  id?: number;
  name?: string | null;
  disabled?: boolean | null;
  tokens?: IToken[] | null;
}

export const defaultValue: Readonly<IBlockchain> = {
  disabled: false,
};
