import { IFaqCategory } from 'app/shared/model/faq-category.model';

export interface IFaq {
  id?: number;
  question?: string | null;
  answer?: string | null;
  disabled?: boolean | null;
  order?: number | null;
  faqCategory?: IFaqCategory | null;
}

export const defaultValue: Readonly<IFaq> = {
  disabled: false,
};
