import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TransactionEvent from './transaction-event';
import TransactionEventDetail from './transaction-event-detail';
import TransactionEventUpdate from './transaction-event-update';
import TransactionEventDeleteDialog from './transaction-event-delete-dialog';

const TransactionEventRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TransactionEvent />} />
    <Route path="new" element={<TransactionEventUpdate />} />
    <Route path=":id">
      <Route index element={<TransactionEventDetail />} />
      <Route path="edit" element={<TransactionEventUpdate />} />
      <Route path="delete" element={<TransactionEventDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TransactionEventRoutes;
