import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Parameter from './parameter';
import ParameterDetail from './parameter-detail';
import ParameterUpdate from './parameter-update';
import ParameterDeleteDialog from './parameter-delete-dialog';

const ParameterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Parameter />} />
    <Route path="new" element={<ParameterUpdate />} />
    <Route path=":id">
      <Route index element={<ParameterDetail />} />
      <Route path="edit" element={<ParameterUpdate />} />
      <Route path="delete" element={<ParameterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ParameterRoutes;
