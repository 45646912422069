import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TokenBlockchain from './token-blockchain';
import TokenBlockchainDetail from './token-blockchain-detail';
import TokenBlockchainUpdate from './token-blockchain-update';
import TokenBlockchainDeleteDialog from './token-blockchain-delete-dialog';

const TokenBlockchainRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TokenBlockchain />} />
    <Route path="new" element={<TokenBlockchainUpdate />} />
    <Route path=":id">
      <Route index element={<TokenBlockchainDetail />} />
      <Route path="edit" element={<TokenBlockchainUpdate />} />
      <Route path="delete" element={<TokenBlockchainDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TokenBlockchainRoutes;
