export interface IFaqCategory {
  id?: number;
  name?: string | null;
  disabled?: boolean | null;
  order?: number | null;
}

export const defaultValue: Readonly<IFaqCategory> = {
  disabled: false,
};
